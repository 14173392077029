// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'

import messages from '../../../components/ServicePages/ServicePages.lang'
import baseStyles from './IntegrationExample.scss'
import { FormattedMessageSanitized } from '../../FormattedMessageSanitized'

type Props = {
  ...StyleProps,
  description?: any,
  integrationTitle?: string,
  exampleTitle?: string,
  integrationLogo?: any,
  exampleLogo?: any,
  exampleText?: any,
  exampleTech?: any,
  exampleStart?: any,
  exampleLive?: any
}

const IntegrationExample = ({
  styles,
  description,
  integrationTitle,
  exampleTitle,
  integrationLogo,
  exampleLogo,
  exampleText,
  exampleTech,
  exampleStart,
  exampleLive
}: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      {description && (
        <p>
          <FormattedMessageSanitized {...description} />
        </p>
      )}
      <p className={styles.integrationTitle}>
        <strong>{integrationTitle}</strong>
      </p>
      {integrationLogo}
      <p className={styles.exampleTitle}>{exampleTitle}</p>
      {exampleLogo}
      {typeof exampleText === 'string' ? (
        <p dangerouslySetInnerHTML={{ __html: exampleText }}></p>
      ) : (
        <p>
          <FormattedMessageSanitized {...exampleText} />
        </p>
      )}

      <p>
        {exampleTech && (
          <>
            <strong>{formatMessage(messages.exampleTech)}: </strong>
            {exampleTech}
            <br />
          </>
        )}
        {exampleStart && (
          <>
            <strong>{formatMessage(messages.exampleStart)}: </strong>
            {exampleStart}
            <br />
          </>
        )}
        {exampleLive && (
          <>
            <strong>{formatMessage(messages.exampleLive)}: </strong>
            {exampleLive}
          </>
        )}
      </p>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(IntegrationExample)
